@import 'https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
:root {
  --fontFamily: 'Barlow Condensed', sans-serif;
  --fontFamily2: 'Mulish', sans-serif;
  --mainColor: #3f9f42;
  --whiteColor: #ffffff;
  --blackColor: #071327;
  --paragraphColor: #666666;
  --card-title-fontSize: 24px;
  --fontSize: 16px;
  --transition: 0.5s;
  --boxShadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--fontFamily);
}
a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--blackColor);
  text-decoration: none;
  outline: 0 !important;
}
a:hover {
  color: var(--mainColor);
  text-decoration: none;
}
:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.form-control {
  border: 1px solid transparent !important;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.form-control:focus {
  border: 1px solid var(--mainColor) !important;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  font-family: var(--fontFamily2);
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pt-30 {
  padding-top: 0;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.section-content {
  margin-top: 20px;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
.sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  padding: 0;
  background-color: var(--whiteColor) !important;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.bg-0a0144 {
  background-color: #0a0144;
}
.bg-f8f8f8 {
  background-color: #f8f8f8;
}
.bg-071327 {
  background-color: #071327;
}
.bg-f8f9fc {
  background-color: #f8f9fc;
}
.bg-f6f2ef {
  background-color: #f6f2ef;
}
.topbar-content {
  padding: 5px 30px;
  position: relative;
  z-index: 9;
}
.topbar-left-side ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.topbar-left-side ul li {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 0;
  font-size: 15px;
  font-family: var(--fontFamily2);
}
.topbar-left-side ul li i {
  margin-right: 3px;
  color: var(--mainColor);
}
.topbar-left-side ul li a {
  color: rgba(255, 255, 255, 0.8);
}
.topbar-right-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}
.topbar-right-area .option-select-area {
  margin-left: 15px;
  margin-right: 15px;
}
.topbar-right-area .option-select-area .fa-globe {
  color: var(--mainColor);
}
.topbar-right-area .topbar-social-icons ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.topbar-right-area .topbar-social-icons ul li {
  display: inline-block;
}
.topbar-right-area .topbar-social-icons ul li a {
  width: 25px;
  height: 25px;
  padding-top: 1px;
  font-size: 12px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--whiteColor);
  background-color: #707070;
}
.topbar-right-area .topbar-social-icons ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.nice-select {
  border: 0;
  background-color: transparent;
  height: auto;
  width: 100%;
  line-height: unset;
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 23px;
  padding-right: 15px;
}
.nice-select .selected {
  color: var(--mainColor);
}
.nice-select .list {
  width: 100%;
  z-index: 9999;
}
.option-select-area {
  position: relative;
  display: inline-block;
  max-width: 100%;
  color: var(--paragraphColor);
}
.option-select-area .fa-globe {
  position: absolute;
  top: 6px;
  left: 0;
  z-index: 99;
  color: var(--whiteColor);
}
.option-select-area .nice-select .list {
  width: 85px;
  z-index: 999;
}
.option-select-area .nice-select::after {
  right: 2px;
  width: 7px;
  height: 7px;
  border-color: var(--whiteColor);
}
.option-select-area .nice-select .current {
  color: var(--whiteColor);
  font-size: 14px;
}
a.meanmenu-reveal {
  display: none;
}
.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: absolute;
  background: 0 0;
  padding: 20px 0 0;
  z-index: 2;
  height: 60px;
  text-transform: capitalize;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.mean-container a.meanmenu-reveal {
  width: 35px;
  height: 30px;
  padding: 12px 15px 0 0;
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--mainColor);
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-weight: 700;
}
.mean-container a.meanmenu-reveal span {
  display: block;
  background: var(--mainColor);
  height: 4px;
  margin-top: 5px;
  border-radius: 3px;
}
.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: var(--whiteColor);
  margin-top: 64px;
}
.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  list-style-type: none;
}
.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}
.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #677294;
  border-top: 1px solid #dbeefd;
  text-decoration: none;
  text-transform: capitalize;
}
.mean-container .mean-nav ul li a:hover {
  background: var(--whiteColor);
}
.mean-container .mean-nav ul li a.active {
  color: var(--whiteColor);
}
.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 1em 10%;
  color: #677294;
  border-top: 1px solid #dbeefd;
  opacity: 1;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
  text-transform: none;
  font-size: 15px;
  text-transform: capitalize;
}
.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}
.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}
.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}
.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 3px;
  width: 100%;
  height: 24px;
  padding: 12px !important;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: 0 0 !important;
  border: none !important;
}
.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0;
}
.mean-container .mean-nav .navbar-nav {
  overflow-y: scroll;
  height: 100%;
}
.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}
.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}
.mean-nav .dropdown-toggle::after {
  display: none;
}
.mean-remove {
  display: none !important;
}
.mobile-nav {
  display: none;
  position: relative;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}
.btn-primary,
.btn-primary:active {
  background-color: var(--whiteColor) !important;
  color: var(--blackColor) !important;
  border-color: var(--blackColor) !important;
}
.mobile-nav.mean-container .mean-nav ul li a.active {
  color: var(--mainColor);
}
.main-nav {
  background-color: transparent;
}
.main-nav nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.main-nav nav .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.main-nav nav .navbar-nav .nav-item {
  position: relative;
  padding: 15px 0;
}
.main-nav nav .navbar-nav .nav-item a {
  padding: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  color: var(--blackColor);
  font-size: 15px;
  font-weight: 700;
  font-family: var(--fontFamily2);
  margin-left: 14px;
  margin-right: 14px;
}
.main-nav nav .navbar-nav .nav-item a i {
  right: 0;
  top: 7px;
  font-size: 12px;
  position: absolute;
}
.main-nav nav .navbar-nav .nav-item a:hover {
  color: var(--mainColor);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.main-nav nav .navbar-nav .nav-item a.dropdown-toggle {
  padding-right: 15px;
  position: relative;
}
.main-nav nav .navbar-nav .nav-item a.dropdown-toggle::after {
  content: '\f107';
  font-family: 'font awesome 5 free';
  position: absolute;
  top: 2px;
  right: 0;
  border: 0;
  font-size: 13px;
}
.main-nav nav .navbar-nav .nav-item a.active {
  color: var(--mainColor);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.main-nav nav .navbar-nav .nav-item:hover a {
  color: var(--mainColor);
}
.main-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  visibility: visible;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu {
  left: 0;
  top: 60px;
  opacity: 0;
  z-index: 99;
  width: 200px;
  border: none;
  display: block;
  border-radius: 0;
  visibility: hidden;
  position: absolute;
  -webkit-transform: rotateX(-20deg);
  transform: rotateX(-20deg);
  -webkit-transform-origin: top;
  transform-origin: top;
  background: var(--whiteColor);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 10px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li {
  position: relative;
  padding: 0;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  margin: 0;
  display: block;
  padding: 7px 20px;
  color: var(--blackColor);
  border-bottom: 1px dashed transparent;
  font-size: 15px;
  font-weight: 600;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a i {
  top: 50%;
  position: absolute;
  right: 10px !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a::before {
  content: '\f0da';
  font-size: 0;
  color: var(--mainColor);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
  padding-left: 25px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover::before,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active::before {
  font-size: 15px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  position: absolute;
  left: 101%;
  top: 0;
  opacity: 0 !important;
  visibility: hidden !important;
}
.main-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  .dropdown-menu {
  top: 20px;
  left: 100%;
  position: absolute;
  opacity: 0 !important;
  visibility: hidden !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--blackColor);
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.main-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: var(--mainColor);
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -5px !important;
}
.main-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li:hover
  .dropdown-menu
  li:hover
  .dropdown-menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -5px !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
  color: var(--mainColor);
}
.main-nav nav .navbar-nav .nav-item:last-child .dropdown-menu {
  left: auto;
  right: 0;
}
.navbar-light .navbar-brand {
  color: var(--mainColor);
  font-weight: 700;
  text-transform: uppercase;
}
.navbar-light .navbar-brand:hover {
  color: var(--mainColor);
  font-weight: 700;
  text-transform: uppercase;
}
.mean-container .mean-bar {
  position: relative;
}
.mobile-nav .menu-sidebar-small-device {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 15px;
  right: 47px;
}
.mobile-nav .menu-sidebar-small-device .uni-button {
  padding: 5px 10px;
}
.menu-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menu-sidebar ul li {
  margin-left: 15px;
  line-height: 1;
}
.menu-sidebar ul li .default-button {
  margin-bottom: -3px;
}
.menu-small-device {
  display: none;
  position: absolute;
  right: 80px;
  top: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-small-device li {
  list-style: none;
  display: inline-block;
}
.header-1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.header-1 .navbar {
  padding: 10px 30px;
  border-radius: 0 0 10px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 5;
}
.header-1 .navbar .navbar-nav .nav-item a {
  color: var(--whiteColor);
}
.header-1 .navbar .navbar-nav .nav-item .dropdown-menu {
  top: 68px;
}
.header-1 .sticky {
  background-color: #000 !important;
}
.header-area-2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.header-area-2 .navbar {
  background-color: transparent;
  position: relative;
  z-index: 5;
}
.header-3 .navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 13px;
  padding-bottom: 10px;
  background-color: transparent;
}
.popup-button {
  border: 0;
  font-size: 18px;
  border-radius: 0;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  width: 50px;
  height: 41px;
  display: inline-block;
}
.popup {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  visibility: hidden;
  background-color: #000000d4;
}
.popup .popup-content {
  width: 0%;
  height: 100%;
  opacity: 0;
  margin-left: 0;
  margin-right: auto;
}
.popup .popup-content .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  color: var(--mainColor);
  border: 0;
  font-size: 30px;
}
.popup .popup-content form {
  top: 20%;
  left: 50%;
  width: 80%;
  visibility: hidden;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.popup .popup-content form .search-box {
  border-radius: 10px;
  border: 1px solid var(--mainColor);
}
.popup .popup-content form .search-box input {
  padding: 5px 20px;
}
.popup .popup-content form .search-box .form-control {
  border-radius: 10px;
  border: 0 solid transparent !important;
}
.popup .popup-content form .search-box .btn {
  padding: 5px 15px;
  color: var(--whiteColor);
  border-radius: 10px;
  background-color: var(--mainColor);
  font-size: 25px;
}
.popup .hi {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup .hi form {
  visibility: visible;
  top: 50% !important;
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}
.small-device-popup-btn {
  border: 0;
  border-radius: 5px;
  font-size: 20px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  color: var(--mainColor);
  background-color: transparent;
  margin-right: 15px;
}
.small-device-popup-btn:hover {
  color: var(--mainColor);
}
.small-device-popup {
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  position: fixed;
  z-index: 999999;
  visibility: hidden;
  background-color: #000;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.small-device-popup .sidebar-popup-content {
  height: 100%;
  opacity: 0;
  margin-left: 0;
  margin-right: auto;
}
.small-device-popup .sidebar-popup-content .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  color: var(--mainColor);
  border: 0;
  font-size: 30px;
  z-index: 99;
}
.small-device-popup .sidebar-popup-content .sdp-content {
  padding: 100px 15px 50px 25px;
  position: relative;
  text-align: center;
}
.small-device-popup .sidebar-popup-content .sdp-content .footer-social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}
.small-device-popup
  .sidebar-popup-content
  .sdp-content
  .footer-social-icons
  span {
  margin-right: 10px;
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 600;
  font-family: var(--fontFamily2);
}
.small-device-popup
  .sidebar-popup-content
  .sdp-content
  .footer-social-icons
  ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.small-device-popup
  .sidebar-popup-content
  .sdp-content
  .footer-social-icons
  ul
  li {
  display: inline-block;
}
.small-device-popup
  .sidebar-popup-content
  .sdp-content
  .footer-social-icons
  ul
  li
  a {
  width: 30px;
  height: 30px;
  font-size: 15px;
  margin-left: 3px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px -5px #ccc;
  box-shadow: 0 0 15px -5px #ccc;
}
.small-device-popup
  .sidebar-popup-content
  .sdp-content
  .footer-social-icons
  ul
  li
  a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.small-device-popup .sidebar-popup-content form {
  position: relative;
  max-width: 95%;
  margin-left: 0;
  margin-right: auto;
  margin-top: 50px;
}
.small-device-popup .sidebar-popup-content form .input-group {
  border-radius: 10px;
  border: 1px solid var(--mainColor);
}
.small-device-popup .sidebar-popup-content form .input-group input {
  padding: 5px 20px;
}
.small-device-popup .sidebar-popup-content form .input-group .form-control {
  border-radius: 10px;
  border: 0 solid transparent !important;
}
.small-device-popup .sidebar-popup-content form .input-group .btn {
  padding: 5px 15px;
  color: var(--whiteColor);
  border-radius: 10px;
  background-color: var(--mainColor);
  font-size: 20px;
}
.small-device-popup .sidebar-popup-content .sdp-footer-area {
  text-align: left;
  margin-top: 30px;
}
.small-device-popup .sidebar-popup-content .sdp-footer-area h3 {
  margin-bottom: 12px;
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 700;
}
.small-device-popup .sidebar-popup-content .sdp-footer-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.small-device-popup .sidebar-popup-content .sdp-footer-area ul li {
  margin-top: 10px;
  font-size: 15px;
}
.small-device-popup .sidebar-popup-content .sdp-footer-area ul li i {
  color: var(--mainColor);
  margin-right: 5px;
}
.small-device-popup .sidebar-popup-content .sdp-footer-area ul li a {
  color: var(--whiteColor);
}
.small-device-popup .s-active {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.small-device-popup .s-active form {
  visibility: visible;
  top: 50% !important;
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}
@media only screen and (max-width: 767px) {
  .topbar-right-side {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .topbar-left-side {
    text-align: center;
  }
  .topbar-left-side ul li {
    margin-right: 7px;
    margin-left: 7px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .topbar-right-side {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .topbar-left-side {
    text-align: center;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-nav nav .navbar-nav .nav-item a {
    font-size: 14px;
    margin-left: 13px;
    margin-right: 13px;
  }
}
@media only screen and (max-width: 1199px) {
  .main-responsive-nav {
    background-color: #fff;
    height: 65px;
    position: relative;
    z-index: 5;
  }
  .mobile-nav {
    display: block;
    position: relative;
  }
  .mobile-nav .logo {
    top: 14px;
    left: 0;
    z-index: 999;
    position: absolute;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
  }
  .mobile-nav nav .navbar-nav {
    max-height: 64vh;
    overflow-y: scroll;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .mobile-nav nav .navbar-nav .nav-item a i {
    display: none;
  }
  .mobile-nav nav .navbar-nav::-webkit-scrollbar {
    width: 7px;
  }
  .mobile-nav nav .navbar-nav::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .mobile-nav nav .navbar-nav::-webkit-scrollbar-thumb {
    background: #888;
  }
  .mobile-nav nav .navbar-nav::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .mean-container .mean-bar {
    background-color: #fff;
    padding: 0;
  }
  .mean-container a.meanmenu-reveal {
    padding: 5px 2px 0 0;
    -webkit-transition: 0s !important;
    transition: 0s !important;
  }
  .main-nav {
    display: none !important;
  }
  .menu-sidebar {
    display: none;
  }
  .menu-small-device {
    z-index: 2;
    right: 45px;
    top: 16px;
    display: block !important;
  }
  .menu-small-device .default-button {
    padding: 7px 10px;
    font-size: 14px;
  }
  .meanclose {
    top: 60% !important;
  }
  .header-1,
  .header-area-2 {
    position: relative;
  }
  .topbar {
    background-color: var(--blackColor);
  }
  .topbar-left-area {
    text-align: center;
  }
  .topbar-left-area ul li {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mobile-nav .call-button {
    top: 0;
  }
}
.footer-logo-area p {
  color: #b8b8b9;
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer-social-icons ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-social-icons ul li {
  display: inline-block;
}
.footer-social-icons ul li a {
  width: 30px;
  height: 30px;
  font-size: 15px;
  margin-right: 5px;
  padding-top: 1px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--paragraphColor);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--whiteColor);
}
.footer-social-icons ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.footer-social-icons ul li:last-child a {
  margin-right: 0;
}
.footer-links h3 {
  color: var(--whiteColor);
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: var --card-title-fontSize;
  font-weight: 600;
}
.footer-links a {
  color: rgba(255, 255, 255, 0.8);
}
.footer-links a:hover {
  color: var(--mainColor);
}
.footer-news-card {
  margin-top: 15px;
}
.footer-news-card p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #b8b8b9;
}
.footer-news-card h5 {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 18px;
  font-weight: 600;
}
.footer-contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-contact ul li {
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  padding-left: 115px;
}
.footer-contact ul li span {
  color: #b8b8b9;
  margin-right: 10px;
  display: inline-block;
  position: absolute;
  left: 0;
  font-size: 15px;
  font-family: var(--fontFamily2);
}
.footer-newsletter p {
  color: #b8b8b9;
}
.footer-newsletter .newsletter-form .form-control {
  background-color: #313b4c;
  color: var(--whiteColor);
  padding: 12px 15px;
  border: 0;
}
.footer-newsletter .newsletter-form .form-control::-webkit-input-placeholder {
  color: #979797;
}
.footer-newsletter .newsletter-form .form-control:-ms-input-placeholder {
  color: #979797;
}
.footer-newsletter .newsletter-form .form-control::-ms-input-placeholder {
  color: #979797;
}
.footer-newsletter .newsletter-form .form-control::placeholder {
  color: #979797;
}
.footer-newsletter .newsletter-form .default-button {
  margin-top: 15px;
  width: 100%;
  padding: 12px 15px;
}
.validation-danger {
  color: red;
}
.validation-success {
  color: green;
}
.copyright {
  text-align: center;
  color: var(--whiteColor);
  position: relative;
  padding-top: 0;
  padding-bottom: 20px;
}
.copyright .container {
  border-top: 1px solid #ffffff1e;
}
.copyright p {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 20px;
  font-size: 15px;
}
.copyright p a {
  font-weight: 600;
  text-decoration: none;
  color: var(--mainColor);
}
.copyright p strong {
  color: var(--whiteColor);
}
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 0;
  right: 20px;
  border-radius: 5px;
  color: var(--mainColor);
  z-index: 99;
  height: 40px;
  width: 50px;
  display: inline-block;
  padding: 0 10px 45px;
  text-align: center;
  opacity: 1;
  visibility: hidden;
  font-size: 25px;
  -webkit-transition: var(--transition);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px 2px rgba(7, 7, 7, 0.1);
}
.go-top i {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 9;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.go-top:hover {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.go-top.active {
  opacity: 1;
  visibility: visible;
  bottom: 65px;
  -webkit-animation: top-bottom 5s infinite linear;
  animation: top-bottom 5s infinite linear;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a::before {
  content: '\f0da';
  font-family: 'font awesome 5 free';
}
.default-button {
  padding: 15px 25px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  border: 0;
  overflow: hidden !important;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  font-size: 15px;
  font-weight: 600;
  font-family: var(--fontFamily2);
}
.default-button::before {
  content: '';
  width: 0%;
  height: 2px;
  overflow: hidden;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  background-color: #fff;
  position: absolute;
  right: unset;
  left: 0;
  top: 0;
  z-index: 0;
}
.default-button::after {
  content: '';
  width: 2px;
  height: 0%;
  overflow: hidden;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: unset;
  z-index: 0;
}
.default-button i {
  font-size: 12px;
  margin-left: 5px;
}
.default-button:hover {
  color: var(--whiteColor);
}
.default-button:hover::before {
  left: unset;
  right: 0;
  width: 100%;
}
.default-button:hover::after {
  height: 100%;
  bottom: 0;
  top: unset;
}
.default-button-2 {
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
  background-color: var(--whiteColor);
}
.default-button-2:hover {
  background-color: var(--mainColor);
}
.read-more-btn {
  font-size: 15px;
  font-weight: 600;
  font-family: var(--fontFamily2);
}
.read-more-btn i {
  font-size: 14px !important;
  margin-left: 2px;
  position: relative;
  top: 1px;
}
.default-button-middle {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.default-section-title span {
  position: relative;
  margin-bottom: 15px;
  display: inline-block;
  padding-left: 15px;
  line-height: 1;
  color: var(--mainColor);
  font-size: 18px;
  font-weight: 500;
  font-family: var(--fontFamily);
}
.default-section-title span::before {
  content: '';
  width: 3px;
  height: 100%;
  background-color: var(--mainColor);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.default-section-title h3 {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 700;
}
.default-section-title-middle {
  max-width: 680px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.gradient-bg1 {
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ffffff),
    color-stop(#e2f3f7),
    color-stop(#ffffff),
    color-stop(#e2f3f7),
    color-stop(#ffffff),
    to(#e2f3f7)
  );
  background: linear-gradient(
    to bottom right,
    #ffffff,
    #e2f3f7,
    #ffffff,
    #e2f3f7,
    #ffffff,
    #e2f3f7
  );
  background-size: 250%;
  -webkit-animation: background-colors 20s infinite linear forwards;
  animation: background-colors 20s infinite linear forwards;
}
.main-banner {
  position: relative;
}
.main-banner .banner-text-area {
  position: relative;
  max-width: 800px;
  padding-top: 315px;
  padding-bottom: 200px;
}
.main-banner-single-slider {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../images/landingPage/banner-1-1.jpg);
  position: relative;
}
.main-banner-single-slider::before {
  content: '';
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#000000fa),
    color-stop(#00000069),
    to(#00000000)
  );
  background-image: linear-gradient(to right, #000000fa, #00000069, #00000000);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.main-banner-single-slider .container {
  position: relative;
  z-index: 1;
}
.mbs-2 {
  background-image: url(../images/banner/banner-1-2.jpg);
}
.mbs-3 {
  background-image: url(../images/banner/banner-1-3.jpg);
}
.banner-text-area {
  position: relative;
  z-index: 1;
}
.banner-text-area h6 {
  color: var(--mainColor);
  font-weight: 500;
  font-size: 18px;
  font-family: var(--fontFamily);
}
.banner-text-area h1 {
  color: var(--whiteColor);
  margin-top: 12px;
  margin-bottom: 20px;
  font-size: 90px;
  font-weight: 700;
}
.banner-text-area p {
  max-width: 720px;
  color: rgba(255, 255, 255, 0.9);
}
.banner-text-area .banner-buttons ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.banner-text-area .banner-buttons ul li {
  display: inline-block;
  margin-right: 15px;
}
.banner-text-area .banner-buttons ul li .default-button {
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.banner-text-area .banner-buttons ul li .banner-button {
  color: var(--blackColor);
  background-color: var(--whiteColor);
}
.banner-text-area .banner-buttons ul li .banner-button:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.banner-text-area .banner-buttons ul li:last-child {
  margin-right: 0;
}
.slider-nav {
  position: relative;
}
.slider-nav .owl-nav {
  width: 99%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  z-index: 0;
  opacity: 0;
}
.slider-nav .owl-nav button {
  width: 40px;
  height: 40px;
  font-size: 14px !important;
  border-radius: 50%;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  color: var(--blackColor) !important;
  background-color: var(--whiteColor) !important;
}
.slider-nav .owl-nav button:hover {
  color: var(--whiteColor) !important;
  background-color: var(--mainColor) !important;
}
.slider-nav:hover .owl-nav {
  opacity: 1;
}
.features-card {
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  padding: 30px 30px 25px 105px;
  background-color: var(--whiteColor);
}
.features-card i {
  font-size: 55px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  position: absolute;
  left: 30px;
  z-index: 1;
}
.features-card h4 {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.features-card h4 a {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.features-card p {
  z-index: 1;
  position: relative;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.features-card::before {
  content: '';
  width: 0%;
  height: 100%;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--mainColor);
  position: absolute;
  left: unset;
  right: 0;
  top: 0;
  z-index: 0;
}
.features-card:hover i,
.features-card:hover h4,
.features-card:hover a,
.features-card:hover p {
  color: var(--whiteColor);
}
.features-card:hover::before {
  width: 100%;
  right: unset;
  left: 0;
}
.about-img-area {
  position: relative;
  padding: 30px;
}
.about-img-area .main-img {
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.about-img-area .small-img {
  max-width: 40%;
  border-radius: 10px;
  border-top: 10px solid #fff;
  border-left: 10px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.about-img-area::before {
  content: '';
  width: 25%;
  height: 25%;
  border-radius: 0 0 0 10px;
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  background-color: var(--mainColor);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.about-img-area .about-exp {
  display: inline-block;
  padding: 20px 20px 15px;
  text-align: center;
  background-color: rgba(63, 159, 66, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.about-img-area .about-exp h4 {
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 700;
}
.about-img-area .about-exp p {
  color: var(--whiteColor);
  font-size: 14px;
}
.about-text-area .default-section-title {
  margin-bottom: 15px;
}
.about-intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}
.about-intro img {
  border-radius: 50%;
  margin-right: 15px;
}
.about-intro h4 {
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: 700;
}
.about-intro p {
  font-size: 14px;
  margin-top: 8px !important;
}
.barfiller {
  position: relative;
  height: 10px;
  border: 0;
  padding-bottom: 5px;
  margin-top: 45px;
  margin-bottom: 55px;
  background-color: #72727213;
  border-radius: 20px;
}
.barfiller .label {
  position: absolute;
  top: -35px;
  color: var(--blackColor);
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 600;
}
.barfiller .tip {
  margin-top: -40px;
  color: var(--blackColor);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--fontFamily);
  border-radius: 5px;
  background-color: transparent;
}
.barfiller .tip::after {
  left: 12px;
  border: 0;
}
.barfiller .fill {
  background-color: var(--mainColor) !important;
  border-radius: 20px 50px 0% 20px !important;
}
.barfiller:last-child {
  margin-bottom: 0;
}
.progress-bar-area {
  margin-top: 60px;
}
.progress-bar-area .barfiller:nth-child(even) .fill {
  background-color: var(--blackColor) !important;
}
.service-card {
  position: relative;
  border-radius: 10px;
  margin-top: 30px;
  overflow: hidden;
  padding-bottom: 10px;
}
.service-card::before {
  content: '';
  width: 80%;
  height: 10%;
  border-radius: 10px;
  background-color: #707070;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.service-card::after {
  content: '';
  width: 0%;
  height: 10%;
  border-radius: 10px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--mainColor);
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.service-card .service-card-img {
  position: relative;
}
.service-card .service-card-img::before {
  content: '';
  width: 100%;
  height: 0%;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.service-card .service-card-img i {
  font-size: 0;
  color: var(--whiteColor);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.service-card .service-card-text {
  z-index: 1;
  position: relative;
  padding: 25px 30px 20px;
  border-radius: 0 0 10px 10px;
  background-color: var(--blackColor);
}
.service-card .service-card-text h4 {
  margin-bottom: 0;
  line-height: 1.4;
  font-weight: 500;
  font-size: var(--card-title-fontSize);
}
.service-card .service-card-text h4 a {
  color: var(--whiteColor);
}
.service-card:hover::after {
  width: 80%;
}
.service-card:hover .service-card-img::before {
  height: 100%;
}
.service-card:hover .service-card-img i {
  font-size: 55px;
}
.service-card:hover .service-card-text h4 a {
  color: var(--mainColor);
}
.why-only-we-text-area p {
  margin-top: 15px;
  margin-bottom: 0;
}
.why-we-list ul {
  margin: 5px 0 0;
  padding: 0;
  list-style: none;
}
.why-we-list ul li {
  display: inline-block;
  margin-top: 10px;
  width: 45%;
  font-weight: 600;
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
}
.why-we-list ul li i {
  color: var(--mainColor);
  margin-right: 5px;
}
.why-only-img-area {
  padding: 50px;
  position: relative;
}
.why-only-img-area img {
  border-radius: 10px;
}
.why-only-img-area .ww-team-card {
  border-radius: 10px;
  display: inline-block;
  padding: 20px 30px 15px;
  text-align: center;
  background-color: rgba(63, 159, 66, 0.95);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.why-only-img-area .ww-team-card h4 {
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 700;
}
.why-only-img-area .ww-team-card p {
  color: var(--whiteColor);
  font-size: 17px;
  font-family: var(--fontFamily);
}
.why-only-img-area .ww-project-card {
  border-radius: 10px;
  display: inline-block;
  padding: 20px 30px 15px;
  text-align: center;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  background-color: var(--whiteColor);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.why-only-img-area .ww-project-card h4 {
  color: var(--mainColor);
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 700;
}
.why-only-img-area .ww-project-card p {
  font-size: 17px;
  font-family: var(--fontFamily);
}
.portfolio-card {
  margin-top: 30px;
  position: relative;
}
.portfolio-card img {
  border-radius: 10px;
}
.portfolio-card .portfolio-card-text-area {
  background-color: var(--mainColor);
  border-radius: 0 10px 0 10px;
  padding: 30px 10px 25px 25px;
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  -webkit-transform-origin: left;
  transform-origin: left;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 95%;
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
}
.portfolio-card .portfolio-card-text-area h4 {
  margin-bottom: 5px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.portfolio-card .portfolio-card-text-area h4 a {
  color: var(--whiteColor);
}
.portfolio-card .portfolio-card-text-area p {
  color: rgba(255, 255, 255, 0.8);
}
.portfolio-card .portfolio-card-text-area a i {
  width: 45px;
  height: 35px;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  position: absolute;
  right: 0;
  top: 0;
}
.portfolio-card:hover .portfolio-card-text-area {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}
.fun-facts-1 {
  position: relative;
}
.fun-facts-1::before {
  content: '';
  width: 100%;
  height: 50%;
  background-color: var(--whiteColor);
  position: absolute;
  left: 0;
  bottom: 0;
}
.fun-facts-content {
  z-index: 1;
  position: relative;
  border-radius: 10px;
  padding: 50px 50px 45px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
}
.fun-facts-text-area p {
  margin-top: 12px;
}
.fun-facts-card {
  text-align: center;
}
.fun-facts-card .odo-text {
  position: relative;
  left: 4px;
  top: 7px;
}
.fun-facts-card h2 {
  color: var(--mainColor);
  margin-bottom: 0;
  line-height: 1;
  font-size: 72px;
  font-weight: 700;
}
.fun-facts-card p {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--fontFamily);
}
.fcb h2 {
  color: var(--blackColor);
}
.team-card {
  margin-top: 30px;
  overflow: hidden;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  background-color: var(--whiteColor);
}
.team-card img {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.team-card .team-card-text {
  padding: 30px 20px;
  text-align: center;
}
.team-card .team-card-text h4 {
  margin-bottom: 5px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.team-card .team-card-text p {
  margin-bottom: 10px;
}
.team-card .team-card-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.team-card .team-card-text ul li {
  display: inline-block;
}
.team-card .team-card-text ul li a {
  width: 30px;
  height: 30px;
  padding-top: 1px;
  font-size: 13px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--whiteColor);
  background-color: #707070;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.team-card .team-card-text ul li a:hover {
  background-color: var(--mainColor);
}
.team-card:hover img {
  -webkit-transform: scale(0.9) rotate(2deg);
  transform: scale(0.9) rotate(2deg);
}
.why-we-img-area {
  z-index: 1;
  position: relative;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/why-we/ww2.jpg);
  height: 100%;
}
.why-we-img-area img {
  display: none;
}
.why-we-1 {
  position: relative;
}
.why-we-1 .shape .shape-img {
  position: absolute;
  bottom: 0;
  left: 45%;
  z-index: 1;
}
.why-we-1::before {
  content: '';
  width: 100%;
  height: 100px;
  background-color: var(--whiteColor);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.why-we-card {
  margin-top: 30px;
  border-radius: 10px;
  padding: 25px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--whiteColor);
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  position: relative;
  z-index: 2;
}
.why-we-card h4 {
  margin-bottom: 12px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  font-size: var(--card-title-fontSize);
  font-weight: 700;
}
.why-we-card p {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.why-we-card:hover {
  background-color: var(--mainColor);
}
.why-we-card:hover h4,
.why-we-card:hover p {
  color: var(--whiteColor);
}
.why-we-text-area-1 {
  max-width: 500px;
  padding-top: 100px;
  padding-bottom: 200px;
}
.testimonial-card {
  margin: 30px 15px 15px;
  position: relative;
  padding: 30px 30px 25px 100px;
}
.testimonial-card::before {
  content: '';
  width: 93%;
  height: 100%;
  background-color: var(--whiteColor);
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.testimonial-card img {
  border-radius: 50%;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.testimonial-card p {
  color: var(--blackColor);
  font-size: 17px;
  font-style: italic;
}
.testimonial-card .testimonial-intro-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.testimonial-card .testimonial-intro-area h4 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
}
.testimonial-card .testimonial-intro-area span {
  font-size: 14px;
  font-family: var(--fontFamily2);
}
.testimonial-card .testimonial-card-text {
  position: relative;
  z-index: 2;
}
.stars ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.stars ul li {
  display: inline-block;
}
.stars ul li i {
  color: #ffab00;
}
.testimonial-slider-area {
  position: relative;
}
.testimonial-slider-area .owl-nav {
  margin-top: 30px;
  position: relative;
  z-index: 1;
}
.testimonial-slider-area .owl-nav button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-transition: 0.6s ease-in-out;
  transition: 0.6s ease-in-out;
  color: var(--whiteColor) !important;
  background-color: var(--blackColor) !important;
}
.testimonial-slider-area .owl-nav button:hover {
  background-color: var(--mainColor) !important;
}
.testimonial-slider-area .owl-nav .owl-next {
  float: right;
}
.testimonial-slider-area .owl-dots {
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 13px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 98%;
  z-index: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.testimonial-slider-area .owl-dots .owl-dot {
  width: 100%;
  height: 2px;
  background-color: #d8d8d8;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.testimonial-slider-area .owl-dots .active {
  background-color: var(--mainColor);
}
.booking-form-area p {
  margin-top: 12px;
}
.booking-form-area .form-control {
  margin-top: 5px;
  margin-bottom: 15px;
  border: 0;
  padding: 15px;
  background-color: #ececec;
  font-size: 15px;
  font-family: var(--fontFamily2);
}
.booking-form-area .default-button {
  width: 100%;
  margin-top: 10px;
}
.booking-img {
  position: relative;
  padding: 40px 40px 0 0;
}
.booking-img img {
  border-radius: 10px;
  position: relative;
  z-index: 2;
}
.booking-img .booking-shape img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.booking-img::before {
  content: '';
  width: 30%;
  height: 30%;
  border-radius: 0 10px 0 0;
  background-color: var(--mainColor);
  -webkit-clip-path: polygon(100% 0%, 100% 100%, 0% 0%);
  clip-path: polygon(100% 0%, 100% 100%, 0% 0%);
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 0;
}
.blog-card {
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  background-color: var(--whiteColor);
}
.blog-card::before {
  content: '';
  width: 95%;
  height: 50%;
  border-radius: 15px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--mainColor);
  position: absolute;
  bottom: 0;
  z-index: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.blog-card .blog-img-area {
  position: relative;
}
.blog-card .blog-img-area img {
  border-radius: 10px 10px 0 0;
  width: 100%;
}
.blog-card .blog-img-area .blog-img-date {
  width: 60px;
  height: 60px;
  text-align: center;
  padding-top: 6px;
  border-radius: 50%;
  display: inline-block;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  font-size: 18px;
  position: absolute;
  right: 30px;
  bottom: -30px;
  z-index: 3;
}
.blog-card .blog-img-area .blog-img-date span {
  display: block;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 22px;
  font-weight: 700;
  font-family: var(--fontFamily);
}
.blog-card .blog-img-area .blog-img-date span:last-child {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--fontFamily2);
}
.blog-card .blog-text-area {
  padding: 35px 30px 25px;
  border-radius: 0 0 15px 15px;
  background-color: var(--whiteColor);
  position: relative;
  z-index: 2;
}
.blog-card .blog-text-area .blog-date ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-card .blog-text-area .blog-date ul li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  color: var(--paragraphColor);
  font-size: 15px;
  font-family: var(--fontFamily2);
}
.blog-card .blog-text-area .blog-date ul li::before {
  content: '/';
  font-size: 15px;
  position: absolute;
  right: -10px;
}
.blog-card .blog-text-area .blog-date ul li:last-child {
  margin-right: 0;
}
.blog-card .blog-text-area .blog-date ul li:last-child::before {
  display: none;
}
.blog-card .blog-text-area .blog-date ul li i {
  margin-right: 3px;
  color: var(--mainColor);
}
.blog-card .blog-text-area h4 {
  line-height: 1.4;
  margin-top: 12px;
  margin-bottom: 18px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.blog-card:hover::before {
  bottom: -10px;
}
.blog-date ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-date ul li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  color: var(--paragraphColor);
  font-size: 15px;
  font-family: var(--fontFamily2);
}
.blog-date ul li::before {
  content: '/';
  font-size: 15px;
  position: absolute;
  right: -10px;
}
.blog-date ul li:last-child {
  margin-right: 0;
}
.blog-date ul li:last-child::before {
  display: none;
}
.blog-date ul li i {
  margin-right: 3px;
  color: var(--mainColor);
}
.partner-slider-area {
  background-color: var(--whiteColor);
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  border-radius: 10px;
  padding: 70px;
}
.partner-slider-area img {
  -webkit-filter: opacity(0.5);
  filter: opacity(0.5);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.partner-slider-area img:hover {
  -webkit-filter: opacity(1);
  filter: opacity(1);
}
.partner {
  position: relative;
}
.partner::before {
  content: '';
  width: 100%;
  height: 50%;
  background-color: var(--blackColor);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.home-banner {
  overflow: hidden;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffffff),
    color-stop(#e2f3f7),
    color-stop(#ffffff),
    color-stop(#e2f3f7),
    color-stop(#ffffff),
    to(#e2f3f7)
  );
  background: linear-gradient(
    to right,
    #ffffff,
    #e2f3f7,
    #ffffff,
    #e2f3f7,
    #ffffff,
    #e2f3f7
  );
  background-size: 200%;
  -webkit-animation: background-colors 15s infinite linear forwards;
  animation: background-colors 15s infinite linear forwards;
  position: relative;
  padding-top: 100px;
}
.banner-text-area-2 {
  padding-bottom: 100px;
}
.banner-text-area-2 h1 {
  color: var(--blackColor);
  font-size: 72px;
}
.banner-text-area-2 p {
  color: var(--paragraphColor);
}
.banner-text-area-2 .banner-buttons ul li .banner-button {
  color: var(--mainColor);
}
.features-2-content {
  position: relative;
  margin-top: -110px;
}
.service-card-2 {
  margin: 30px 20px 0;
}
.service-card-2 .service-card-2-text {
  padding: 25px 15px 0;
}
.service-card-2 .service-card-2-text h4 {
  margin-bottom: 12px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.slider-area-nav {
  position: relative;
}
.slider-area-nav .owl-nav {
  opacity: 0;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 105%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.slider-area-nav .owl-nav button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  color: var(--whiteColor) !important;
  background-color: var(--blackColor) !important;
}
.slider-area-nav .owl-nav button:hover {
  background-color: var(--mainColor) !important;
}
.slider-area-nav .owl-nav .owl-next {
  float: right;
}
.slider-area-nav:hover .owl-nav {
  opacity: 1;
}
.about-img-2 {
  position: relative;
  padding: 30px;
}
.about-img-2 .main-img {
  border-radius: 15px;
  position: relative;
  z-index: 2;
}
.about-img-2 .shape .shape1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.about-img-2 .shape .shape2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.about-img-2 .about-exp {
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  padding: 20px 20px 15px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.about-img-2 .about-exp h4 {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 700;
}
.about-img-2 .about-exp p {
  font-size: 14px;
}
.about-text-area-2 p {
  margin-top: 15px;
  margin-bottom: 0;
}
.about-text-area-2 .about-list {
  margin-top: 10px;
}
.about-text-area-2 .about-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.about-text-area-2 .about-list ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin-top: 12px;
  width: 48%;
  font-family: var(--fontFamily2);
  font-size: var(--fontSize);
  font-weight: 600;
}
.about-text-area-2 .about-list ul li i {
  color: var(--mainColor);
  margin-right: 5px;
}
.team-card-2 {
  margin-top: 30px;
  border-radius: 10px;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  background-color: var(--whiteColor);
}
.team-card-2 .team-card-2-img {
  position: relative;
  overflow: hidden;
}
.team-card-2 .team-card-2-img img {
  border-radius: 10px 10px 0 0;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.team-card-2 .team-card-2-img .team-social-icons {
  position: absolute;
  left: 30px;
  bottom: 0;
}
.team-card-2 .team-card-2-img .team-social-icons ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.team-card-2 .team-card-2-img .team-social-icons ul li {
  display: inline-block;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
.team-card-2 .team-card-2-img .team-social-icons ul li a {
  width: 35px;
  height: 30px;
  font-size: 15px;
  border-radius: 5px 5px 0 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--whiteColor);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--blackColor);
}
.team-card-2 .team-card-2-img .team-social-icons ul li a:hover {
  background-color: var(--mainColor);
}
.team-card-2 .team-card-2-img .team-social-icons ul li:nth-child(odd) {
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
}
.team-card-2 .team-card-text-2 {
  padding: 25px 25px 20px 30px;
}
.team-card-2 .team-card-text-2 h4 {
  margin-bottom: 5px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.team-card-2 .team-card-text-2 p {
  font-size: 14px;
}
.team-card-2:hover .team-card-2-img img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.team-card-2:hover .team-card-2-img .team-social-icons ul li {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
.why-only-img-area-2 .ww-main-img {
  position: relative;
  z-index: 1;
}
.why-only-img-area-2 .ww-team-card {
  right: unset;
  left: 0;
}
.why-only-img-area-2 .ww-project-card {
  left: unset;
  right: 0;
}
.why-only-img-area-2 .shape .shape1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.why-only-img-area-2 .shape .shape2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.testimonial-card-2 {
  margin: 30px 15px 0;
}
.testimonial-card-2 .testimonial-card-text-2 {
  text-align: center;
  padding: 20px 20px 60px;
  border: 1px solid #eee;
  background-color: var(--whiteColor);
}
.testimonial-card-2 .testimonial-card-text-2 i {
  font-size: 45px;
  color: #eee;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.testimonial-card-2 .testimonial-card-text-2 p {
  font-style: italic;
  color: var(--blackColor);
}
.testimonial-card-2 .testimonial-card-2-intro {
  text-align: center;
  position: relative;
  margin-top: -40px;
  margin-bottom: -6px;
}
.testimonial-card-2 .testimonial-card-2-intro img {
  border-radius: 50%;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-left: auto;
  margin-right: auto;
}
.testimonial-card-2 .testimonial-card-2-intro h4 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.testimonial-card-2 .testimonial-card-2-intro p {
  font-size: 14px;
}
.testimonial-card-2:hover .testimonial-card-text-2 i {
  color: var(--mainColor);
}
.why-we-img-area-2 {
  padding: 30px;
  position: relative;
}
.why-we-img-area-2 .main-img {
  position: relative;
  border-radius: 10px;
  z-index: 2;
}
.why-we-img-area-2 .shape-img .shape1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.why-we-img-area-2 .shape-img .shape2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.why-we-img-area-2 .shape-img .shape3 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.blog-card-2 .blog-img-area .blog-img-date {
  border-radius: 10px;
}
.blog-card-2 .blog-text-area {
  border: 1px solid #eee;
  border-radius: 0;
  border-top: 0;
}
.blog-card-2::before {
  display: none;
}
.banner {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../images/landingPage/banner-3-bg.jpg);
  position: relative;
  padding-top: 120px;
}
.banner::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.banner::after {
  content: '';
  width: 100%;
  height: 100px;
  background-color: var(--whiteColor);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.banner .shape .shape1 {
  position: absolute;
  bottom: 0;
  left: 45%;
  z-index: 1;
}
.banner .banner-content-3 {
  position: relative;
  z-index: 1;
}
.banner-img-3 {
  position: relative;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../images/landingPage/banner-3-img.jpg);
  height: 100%;
  z-index: 2;
}
.banner-img-3 img {
  display: none;
  border-radius: 10px;
}
.banner-text-area-3 {
  max-width: 580px;
  position: relative;
  z-index: 2;
  padding-top: 100px;
  padding-bottom: 200px;
}
.about-img-area-3 {
  overflow: hidden;
}
.about-img-area-3 .small-img {
  border: 0;
  right: unset;
  left: 0;
}
.about-img-area-3 .about-exp {
  left: 10px;
  border-radius: 10px;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  background-color: var(--whiteColor);
}
.about-img-area-3 .about-exp h4,
.about-img-area-3 .about-exp p {
  color: var(--blackColor);
  font-family: var(--fontFamily);
}
.about-img-area-3 .about-exp p {
  font-size: 16px;
}
.about-img-area-3 .shape .shape1 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  -webkit-animation: top-bottom 2s infinite linear alternate;
  animation: top-bottom 2s infinite linear alternate;
}
.about-img-area-3 .shape .shape2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-animation: top-bottom 4s infinite linear alternate;
  animation: top-bottom 4s infinite linear alternate;
}
.service-card-3 {
  border-radius: 10px;
  margin: 30px 15px 0;
  padding: 25px 30px;
  background-color: var(--whiteColor);
}
.service-card-3 i {
  font-size: 45px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.service-card-3 h4 {
  margin-top: 5px;
  margin-bottom: 12px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.service-card-3 p {
  font-size: 15px;
}
.service-card-3:hover i {
  color: var(--mainColor);
}
.service-slider-area-3 .owl-dots {
  text-align: center;
  margin-top: 15px;
}
.service-slider-area-3 .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  margin: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: #dfe1e3 !important;
}
.service-slider-area-3 .owl-dots .active {
  width: 30px;
  border-radius: 15px;
  background-color: var(--mainColor) !important;
}
.service-3 {
  position: relative;
  overflow: hidden;
}
.service-3 .shape .shape1 {
  position: absolute;
  top: 28%;
  right: 15%;
  z-index: 0;
  text-align: right;
  -webkit-animation: top-bottom 3s infinite linear alternate;
  animation: top-bottom 3s infinite linear alternate;
}
.service-3 .shape .shape1 img {
  width: 70%;
  -webkit-filter: opacity(0.4);
  filter: opacity(0.4);
}
.service-3 .shape .shape2 {
  position: absolute;
  bottom: 16%;
  left: 15%;
  z-index: 0;
  -webkit-animation: top-bottom 4s infinite linear alternate;
  animation: top-bottom 4s infinite linear alternate;
}
.service-3 .shape .shape2 img {
  width: 50%;
}
.portfolio-card-3 {
  position: relative;
  margin: 30px 15px 0;
}
.portfolio-card-3 .portfolio-card-text-3 {
  max-width: 60%;
  border-radius: 10px;
  padding: 20px 20px 15px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: rgba(63, 159, 66, 0.9);
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 2;
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: left;
  transform-origin: left;
}
.portfolio-card-3 .portfolio-card-text-3 h4 {
  margin-bottom: 8px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.portfolio-card-3 .portfolio-card-text-3 h4 a {
  color: var(--whiteColor);
}
.portfolio-card-3 .portfolio-card-text-3 p {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.8);
}
.portfolio-card-3:hover .portfolio-card-text-3 {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.portfolio {
  position: relative;
  overflow: hidden;
}
.portfolio .shape {
  position: absolute;
  left: 14%;
  bottom: 8%;
  z-index: 0;
  -webkit-animation: top-bottom 3s infinite linear alternate;
  animation: top-bottom 3s infinite linear alternate;
}
.why-we-text-3 {
  max-width: 600px;
  padding-top: 100px;
  padding-bottom: 200px;
}
.why-we-text-3 .default-section-title {
  margin-bottom: 15px;
}
.why-we-img-3 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../images/why-we/ww5.jpg);
  height: 100%;
}
.why-we-img-3 img {
  display: none;
}
.why-we-3-card-area {
  margin-top: 20px;
}
.why-we-card-3 {
  position: relative;
  padding-left: 60px;
  max-width: 600px;
  margin-top: 20px;
}
.why-we-card-3:first-child {
  margin-top: 0;
}
.why-we-card-3 i {
  font-size: 45px;
  color: var(--mainColor);
  position: absolute;
  left: 0;
}
.why-we-card-3 h4 {
  margin-bottom: 8px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.why-we-3 {
  overflow: hidden;
}
.fun-facts-3 {
  position: relative;
  margin-top: -100px;
}
.why-only-we-3 .why-only-img-area .main-img {
  position: relative;
  z-index: 1;
}
.why-only-we-3 .why-only-img-area .shape {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.why-only-we-3 .why-only-img-area .ww-project-card {
  left: unset;
  right: 0;
}
.why-only-we-3 .why-only-img-area .ww-team-card {
  left: 0;
  right: unset;
}
.testimonial-3 {
  overflow: hidden;
}
.testimonial-3 .testimonial-text-area {
  max-width: 300px;
  margin-left: auto;
}
.testimonial-3 .testimonial-text-area p {
  margin-top: 12px;
}
.testimonial-3 .testimonial-slider-area-3 {
  position: relative;
}
.testimonial-3 .testimonial-slider-area-3 .owl-nav {
  position: absolute;
  left: -35%;
  bottom: 10%;
}
.testimonial-3 .testimonial-slider-area-3 .owl-nav button {
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-radius: 50%;
  margin: 0 3px;
  color: var(--whiteColor) !important;
  background-color: var(--blackColor) !important;
}
.testimonial-3 .testimonial-slider-area-3 .testimonial-card {
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-3 {
  position: relative;
}
.blog-3::before {
  content: '';
  width: 100%;
  height: 57%;
  background-color: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.blog-3 .container {
  position: relative;
  z-index: 1;
}
.uni-banner {
  overflow: hidden;
  background-size: 200%;
  background-repeat: no-repeat;
  background-image: url(../images/inner-images/uni-banner.jpg);
  -webkit-animation: background-colors 20s infinite linear forwards;
  animation: background-colors 20s infinite linear forwards;
  position: relative;
}
.uni-banner-text-area {
  position: relative;
  text-align: center;
  z-index: 1;
  padding-top: 155px;
  padding-bottom: 100px;
}
.uni-banner-text-area h1 {
  font-size: 48px;
  font-weight: 700;
  font-family: var(--fontFamily);
  margin-bottom: 15px;
}
.uni-banner-text-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.uni-banner-text-area ul li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
  color: var(--mainColor);
  font-weight: 600;
  font-size: 18px;
  font-family: var(--fontFamily);
}
.uni-banner-text-area ul li a {
  color: var(--blackColor);
}
.uni-banner-text-area ul li::after {
  content: '\f101';
  font-size: 12px;
  font-family: 'font awesome 5 free';
  font-weight: 600;
  position: absolute;
  right: -18px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.uni-banner-text-area ul li:last-child::after {
  display: none;
}
.testimonial-4 {
  position: relative;
}
.testimonial-4::before {
  content: '';
  width: 100%;
  height: 50%;
  background-color: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.testimonial-4 .container {
  position: relative;
  z-index: 1;
}
.pricing-card {
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid #eee;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  position: relative;
  padding: 30px;
}
.pricing-card span {
  padding: 8px 15px;
  border-radius: 25px;
  background-color: #f2f0f0;
  position: absolute;
  top: 30px;
  right: 30px;
  font-weight: 600;
  font-size: 20px;
  font-family: var(--fontFamily);
}
.pricing-card h5 {
  font-size: 20px;
  font-weight: 700;
}
.pricing-card h2 {
  margin-bottom: 12px;
  font-size: 48px;
  font-weight: 700;
}
.pricing-card h2 sub {
  font-size: 15px;
  font-weight: 400;
  font-family: var(--fontFamily2);
}
.pricing-card ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pricing-card ul li {
  margin-bottom: 12px;
  font-size: var(--fontSize);
  font-weight: 500;
}
.pricing-card ul li i {
  color: var(--mainColor);
  margin-right: 5px;
}
.pricing-card ul .disabled {
  color: var(--paragraphColor);
}
.pricing-card ul .disabled i {
  color: var(--paragraphColor);
}
.pricing-card .default-button {
  margin-top: 15px;
}
.pricing-card:hover {
  border-color: var(--mainColor);
}
.p-active {
  border-color: var(--mainColor);
}
.accordion-item {
  background-color: transparent;
  margin-top: 15px;
  border: 1px solid #eee !important;
}
.accordion-item .accordion-body {
  background-color: transparent;
  padding-top: 0;
}
.accordion-item .accordion-body p {
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
}
.accordion-button {
  border: 0 !important;
  min-height: 40px;
  position: relative;
  color: var(--blackColor) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: var(--whiteColor);
  padding: 15px 55px 15px 15px;
  border-radius: 5px !important;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--fontFamily);
}
.accordion-button i {
  width: 20px;
  height: 20px;
  font-size: 25px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #a2a2a2;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: #fff;
  position: absolute;
  right: 10px;
}
.accordion-button::after {
  display: none;
}
.accordion-button .fa-minus-circle {
  opacity: 0;
}
.accordion-button:not(.collapsed) {
  background-color: var(--whiteColor);
}
.accordion-button:not(.collapsed) i {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  color: var(--mainColor);
}
.accordion-button:not(.collapsed) .fa-minus-circle {
  opacity: 1;
}
.border-b {
  position: relative;
  border-bottom: 1px solid #eee;
}
.error-content {
  max-width: 600px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.error-content h4 {
  margin-top: 25px;
  margin-bottom: 12px;
  font-size: 36px;
  font-weight: 700;
}
.error-content .input-group {
  border-radius: 25px !important;
  background-color: #f8f8f8;
}
.error-content .input-group .form-control {
  border: 0;
  border-radius: 25px;
  padding: 15px;
  background-color: #f8f8f8;
}
.error-content .input-group .btn {
  width: 40px;
  height: 40px;
  margin-top: 3px;
  border-radius: 50% !important;
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.error-content .default-button {
  margin-top: 15px;
}
.portfolio-contents .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
}
.portfolio-contents .nav .nav-link {
  border: 0;
  color: var(--blackColor);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: #f8f8f8;
  border-radius: 25px;
  margin: 0 3px 5px;
  padding: 5px 25px;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--fontFamily);
}
.portfolio-contents .nav .active {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.portfolio-card-4 {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 30px;
}
.portfolio-card-4::before {
  content: '';
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.portfolio-card-4 .portfolio-card-4-text-area {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 0 25px 25px;
  opacity: 0;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.portfolio-card-4 .portfolio-card-4-text-area h4 {
  margin-bottom: 10px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.portfolio-card-4 .portfolio-card-4-text-area h4 a {
  color: var(--whiteColor);
}
.portfolio-card-4 .portfolio-card-4-text-area p {
  color: var(--whiteColor);
}
.portfolio-card-4:hover::before,
.portfolio-card-4:hover .portfolio-card-4-text-area {
  opacity: 1;
}
.portfolio-cards-area-4 .default-button {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.details-page-title {
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 600;
}
.details-main-img {
  margin-bottom: 25px;
}
.pd-info-area {
  background-color: #f8f8f8;
  padding: 30px 20px;
}
.pd-info-area h3 {
  margin-bottom: 15px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.pd-info-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pd-info-area ul li {
  position: relative;
  padding-left: 80px;
  margin-top: 10px;
}
.pd-info-area ul li span {
  font-weight: 600;
  font-size: 18px;
  font-family: var(--fontFamily);
}
.pd-info-area ul li span:first-child {
  position: absolute;
  left: 0;
}
.pd-info-area ul li span:last-child {
  font-weight: 400;
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
}
.pd-second-area .pds-img-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/inner-images/pd2.jpg);
  height: 100%;
}
.pd-second-area .pds-img-area img {
  display: none;
}
.pds-text-area {
  padding-top: 50px;
  padding-bottom: 50px;
}
.sd-img-area {
  margin-top: 25px;
}
.sd-img-area img {
  margin-bottom: 25px;
}
.sd-list-area .details-page-title {
  margin-bottom: 5px;
}
.sd-list-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sd-list-area ul li {
  display: inline-block;
  margin-top: 12px;
  width: 200px;
  font-weight: 500;
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
}
.sd-list-area ul li i {
  margin-right: 3px;
  color: var(--mainColor);
}
.support-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.support-list ul li {
  margin-top: 12px;
  font-weight: 500;
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
}
.support-list ul li:first-child {
  margin-top: 0;
}
.support-list ul li i {
  margin-right: 5px;
  color: var(--mainColor);
}
.blog-details-text-area {
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
}
.blog-details-text-area .bdt-text {
  padding: 0 30px 25px;
}
.blog-details-text-area .blog-date {
  margin-bottom: 10px;
}
.blog-details-text-area .blog-quote {
  background-color: #f8f8f8;
  padding: 25px 30px;
  position: relative;
  margin-bottom: 15px;
}
.blog-details-text-area .blog-quote::before {
  content: '';
  width: 2px;
  height: 100%;
  background-color: var(--mainColor);
  position: absolute;
  left: 0;
  top: 0;
}
.blog-details-text-area .blog-quote p {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--fontFamily);
  color: var(--blackColor);
}
.blog-text-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 0;
  padding: 3px 15px 15px;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
}
.blog-text-footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-text-footer ul li {
  display: inline-block;
}
.blog-text-footer ul li span {
  font-weight: 600;
  margin-right: 10px;
  padding: 0 !important;
  background-color: transparent !important;
}
.blog-text-footer .tag-area {
  margin-top: 12px;
}
.blog-text-footer .tag-area ul li {
  margin-right: 5px;
  cursor: pointer;
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
}
.blog-text-footer .tag-area ul li i {
  color: var(--paragraphColor);
}
.blog-text-footer .tag-area ul li span {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--fontFamily);
}
.blog-text-footer .tag-area ul li:hover {
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.blog-text-footer .tag-area ul li:hover a {
  color: var(--mainColor);
}
.blog-text-footer .tag-area ul li:first-child {
  padding: 0;
}
.blog-text-footer .social-icons {
  margin-top: 12px;
}
.blog-text-footer .social-icons ul li span {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--fontFamily);
}
.blog-text-footer .social-icons ul li a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--paragraphColor);
  background-color: #f8f8f8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.blog-text-footer .social-icons ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.comment-card {
  margin-top: 30px;
  position: relative;
  padding-left: 115px;
}
.comment-card img {
  border-radius: 50%;
  position: absolute;
  left: 0;
}
.comment-card h5 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 20px;
}
.comment-card span {
  color: var(--paragraphColor);
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--fontFamily2);
}
.comment-card p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.comment-card a {
  font-size: 14px;
  color: var(--mainColor);
  font-weight: 500;
}
.cml-20 {
  margin-left: 50px;
}
.bd-form {
  border-radius: 0;
  margin-top: 30px;
}
.bd-form .form-control {
  border: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 0;
  background-color: #f8f8f8;
}
.bd-form .form-check label {
  color: var(--paragraphColor);
  font-size: var(--fontSize2);
  font-family: var(--fontFamily);
}
.bd-form .default-button {
  margin-top: 15px;
}
.blog-details-page-form-area {
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  padding: 30px;
  margin-top: 30px;
  border-radius: 10px;
}
.sidebar-card {
  padding: 30px 20px;
  border-radius: 10px;
  background-color: #f8f8f8;
}
.sidebar-card h3 {
  margin-bottom: 20px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.sidebar-category ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-category ul li {
  margin-top: 15px;
}
.sidebar-category ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--whiteColor);
  color: var(--paragraphColor);
  padding: 12px 15px;
  border-radius: 5px;
  font-size: var(--fontSize);
  font-weight: 500;
}
.sidebar-category ul li a i {
  color: var(--whiteColor);
  margin-left: 10px;
}
.sidebar-category ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.sidebar-category ul li .active {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.sidebar-contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-contact ul li {
  margin-top: 15px;
  color: var(--paragraphColor);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--fontFamily2);
}
.sidebar-contact ul li i {
  color: var(--mainColor);
  margin-right: 5px;
}
.sidebar-contact ul li a {
  margin-left: 5px;
  color: var(--paragraphColor);
}
.sidebar-contact ul li:first-child {
  margin-top: 0;
}
.search-box .input-group {
  border: 1px solid #eee;
  border-radius: 25px;
  margin-top: 0;
}
.search-box .form-control {
  background-color: var(--whiteColor);
  border: 0;
  padding: 10px 15px;
  border-radius: 25px;
}
.search-box .btn {
  border-radius: 50% !important;
  color: var(--whiteColor);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--mainColor);
}
.search-box .btn:hover {
  background-color: var(--blackColor);
}
.recent-news,
.sd-tag {
  margin-top: 30px;
}
.recent-news-card {
  margin-top: 20px;
  min-height: 90px;
  position: relative;
  padding-left: 105px;
}
.recent-news-card a:hover {
  color: var(--mainColor);
}
.recent-news-card img {
  position: absolute;
  left: 0;
}
.recent-news-card h5 {
  line-height: 1.4;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
}
.recent-news-card p {
  font-size: 14px;
}
.recent-news-card p i {
  margin-right: 3px;
  color: var(--mainColor);
}
.sd-tag ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sd-tag ul li {
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  font-weight: 500;
}
.sd-tag ul li a {
  font-size: 13px;
  padding: 8px 15px 7px;
  border-radius: 25px;
  color: var(--paragraphColor);
  border: 1px solid #eee;
  display: inline-block;
  background-color: var(--whiteColor);
}
.sd-tag ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.sd-tag ul li:last-child {
  margin-right: 0;
}
.paginations {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.paginations ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.paginations ul li {
  display: inline-block;
}
.paginations ul li a {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: var(--whiteColor);
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  font-family: var(--fontFamily2);
  font-size: var(--fontSize);
  font-weight: 700;
}
.paginations ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.paginations ul .active a {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.contact-card-area .default-section-title h3 {
  margin-bottom: 15px;
}
.contact-card {
  margin-top: 15px;
}
.contact-card h4 {
  margin-bottom: 8px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.contact-card h4 i {
  font-size: 20px;
  margin-right: 5px;
  color: var(--mainColor);
}
.contact-card p a {
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  font-weight: 500;
}
.contact-card p a:hover {
  color: var(--mainColor);
}
.contact-card ul {
  margin: 12px 0 0;
  padding: 0;
  list-style: none;
}
.contact-card ul li {
  display: inline-block;
}
.contact-card ul li a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 3px;
  background-color: #e9e9e9;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--paragraphColor);
}
.contact-card ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.contact-page-form-area {
  padding: 30px;
  -webkit-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
}
.contact-page-form-area .default-section-title h3 {
  margin-bottom: 12px;
}
.contact-page-form-area .default-section-title p {
  margin-bottom: 10px;
}
.contact-page-form-area .form-control {
  padding: 15px;
  margin: 10px 0;
  border: 0;
  background-color: #f6f6f6;
  font-size: var(--fontSize);
  font-family: var(--fontFamily2);
}
.contact-page-form-area .default-button {
  width: 100%;
  margin-top: 20px;
}
.with-errors ul li {
  color: red;
}
.google-map {
  width: 100%;
  height: 500px;
}
.google-map .g-map {
  height: 100%;
  width: 100%;
}
.login-form {
  margin-top: 20px;
}
.login-form form label {
  color: var(--blackColor);
  font-family: var(--fontFamily);
  font-size: var(--fontSize);
  font-weight: 600;
}
.login-form form .form-control {
  border: 1px solid #eee !important;
}
.login-form form .form-check label {
  font-weight: 400;
  font-size: 14px;
  font-family: var(--fontFamily2);
}
.login-form form .default-button {
  width: 100%;
}
.login-form p {
  font-size: 14px;
  font-style: italic;
  font-family: var(--fontFamily2);
}
.form-check-input:checked {
  border-color: var(--mainColor);
  background-color: var(--mainColor);
}
.terms {
  margin-bottom: -15px;
}
.terms strong {
  color: var(--blackColor);
}
.terms a {
  color: var(--mainColor);
}
.terms h1,
.terms h2,
.terms h3,
.terms h4,
.terms h5 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-family: var(--fontFamily);
  font-weight: 600;
}
.terms h6 {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--fontFamily);
}
.terms h1 {
  font-size: 30px;
}
.terms h2 {
  font-size: 28px;
}
.terms h3 {
  font-size: 26px;
}
.terms h4 {
  font-size: 24px;
}
.terms h5 {
  font-size: 22px;
}
.terms p {
  font-size: var(--fontSize2);
  font-family: var(--fontFamily2);
}
.terms ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.terms ul li {
  margin: 10px 0;
  font-size: var(--fontSize);
  color: var(--paragraphColor);
  font-family: var(--fontFamily2);
}
.terms ul li span {
  font-weight: 700;
  margin-right: 8px;
  color: var(--blackColor);
}
.terms .payment li {
  position: relative;
  padding-left: 20px;
}
.terms .payment li::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--mainColor);
  position: absolute;
  left: 0;
  top: 6px;
}
.inner-header .main-nav {
  background-color: var(--blackColor);
}
.coming-soon-overlay {
  text-align: center;
}
.coming {
  -webkit-box-shadow: 0 0 20px 1px #eee;
  box-shadow: 0 0 20px 1px #eee;
  padding: 30px;
}
.coming img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
}
.coming h3 {
  text-transform: capitalize;
  color: var(--blackColor);
  padding: 20px 0 0;
  font-size: 45px;
  font-weight: 700;
}
.coming .form-control {
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  border-radius: 25px;
  padding: 12px 15px;
}
.coming .newsletter-form {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.coming p {
  font-size: 15px;
  margin-top: 20px;
  color: var(--paragraphColor);
}
.coming .default-button {
  padding: 13px 20px;
  border-radius: 25px;
  width: 100%;
}
.getting-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.getting-start div {
  border-radius: 8px;
  -ms-flex-preferred-size: 150px;
  flex-basis: 150px;
  padding: 15px 0;
  text-align: center;
  background-color: var(--blackColor);
  -webkit-box-shadow: 1px 1px 20px 1px #aaa9a9;
  box-shadow: 1px 1px 20px 1px #aaa9a9;
}
.getting-start div span {
  font-size: 50px;
  display: block;
  color: #fff;
  font-weight: 700;
}
.getting-start div span:nth-child(2) {
  font-size: 15px;
  text-transform: uppercase;
}
.countdown-wrapper {
  margin: 50px 0;
}
.social-icons ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.social-icons ul li {
  display: inline-block;
}
.social-icons ul li a {
  width: 30px;
  height: 30px;
  font-size: 14px;
  margin: 0 3px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e8e8e8;
  color: var(--paragraphColor);
}
.social-icons ul li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
@-webkit-keyframes background-colors {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  0% {
    background-position: 0% 50%;
  }
}
@keyframes background-colors {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  0% {
    background-position: 0% 50%;
  }
}
@-webkit-keyframes top-bottom {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes top-bottom {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
