@font-face {
  font-family: 'NeoSansArabicRegular';
  src: url("Neo Sans Arabic Regular.otf") format("opentype");
}

 
 
body {
  font-family: 'NeoSansArabicRegular' !important;
  text-align: right;
}

p,a,h1,h2,h3,h4,h5,input,button,select {
  font-family: 'NeoSansArabicRegular' !important;
}

h4 {
  line-height: 2 !important;
}
.empty-image {
  padding: 40px;
}


