.domain_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}

.domain_form {
  border-radius: 12px;
  padding: 25px 90px 40px 90px;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.3);
}

.domain_form_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.arabic-label {
  font-size: 18px;
  margin-bottom: 15px;
  direction: rtl;
  color: #333;
}

.domain-input {
  padding: 12px;
  font-size: 16px;
  width: 300px;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin: 7px 0;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.domain-input:focus {
  border-color: #556b51;
  box-shadow: 0 0 8px rgba(51, 255, 0, 0.2);
  outline: none;
}

.mahaseel-domain-logo {
  width: 250px;
  height: auto;
  display: block;
  margin: 0 auto;
  padding-bottom: 30px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.bouncing-loader {
  display: flex;
  padding: 40px;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #4caf50;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.bouncing-loader > div:nth-child(4) {
  animation-delay: 0.6s;
}
